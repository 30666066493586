:root {
  /* tokens */
  --color-graphite: #323236;
  --color-basalt: #4b4c52;
  --color-carbon: #1e212b;
  --color-indigo: #5e6bf7;
  --color-white: #ffffff;
  --color-dark: #1b1f26;
  --color-dark-bg: #0c0f12;
  --color-grey: #9f9fa4;
  --color-quicksilver: #2b2c33;

  /* vk tokens */
  --color-vk-blue: rgba(0, 119, 255, 1);

  /* colors */
  --color-base: #000000;
  --color-text: var(--color-white);
  --color-text-secondary: var(--color-grey);
  --color-link: #7678fe;
  --color-link-active: #9a9bfe;
  --color-danger: #ef3d52;
  --color-discount: #dc0050;
  --color-success: #3bb54a;
  --color-accent: #fdb93e;
  --color-dark-grey: var(--color-carbon);
  --color-light-grey: #e8e8e8;
  --color-secondary: var(--color-indigo);
  --color-discount-event: #0077ff;
  --color-discount-event-gradient: linear-gradient(
    135.81deg,
    #af49ff 18.63%,
    #493af4 100%
  );
  --color-discount-blue-gradient: linear-gradient(
    137deg,
    #020a9c 11.87%,
    #0177ff 103.61%
  );
  --color-pink-violette-gradient: linear-gradient(
    180deg,
    #7900d9 17.94%,
    #b01bc9 61.53%,
    #fa44bc 94.36%
  );
  --color-rainbow: linear-gradient(
    92deg,
    #fdf579 -5.65%,
    #fcaa7b 10.65%,
    #fcaa7b 10.66%,
    #f87b81 24.11%,
    #f05386 41.23%,
    #dd3d97 60.4%,
    #ab43b4 79.56%,
    #714ed0 91.79%,
    #215af1 105.65%
  );
  --color-upgrade-gradient: linear-gradient(
    135deg,
    #30f2b1 0%,
    #5967ff 50%,
    #ff26a5 100%
  );
  --color-blue: #167ffc;
  --color-discount-blue: #0077ff;
  --color-lavender: #adb8ff;

  /* gray colors */
  --color-grey-anthracite: #0c0f12;
  --color-grey-onyx: #14151c;
  --color-grey-indigo: #5F6378;
  --color-grey-carbon: #202127;
  --color-grey-quicksilver: #2b2c33;
  --color-grey-zinc: #37383e;
  --color-grey-basalt: #4f5055;
  --color-grey-opacity-48: rgba(191, 193, 199, 0.48);
  --color-grey-default: #959699;
  --color-grey-silver: #c4c5c6;
  --color-grey-light: #e8e8e8;

  /* white colors */
  --color-white-opacity-10: rgba(255, 255, 255, 0.1);
  --color-white-opacity-12: rgba(255, 255, 255, 0.12);
  --color-white-opacity-24: rgba(255, 255, 255, 0.24);
  --color-white-opacity-48: rgba(255, 255, 255, 0.48);

  /* black colors */
  --color-black-opacity-12: rgba(0, 0, 0, 0.12);
  --color-black-opacity-24: rgba(0, 0, 0, 0.24);
  --color-black-opacity-48: rgba(0, 0, 0, 0.48);

  /* typo */
  --letter-spacing-default: 0.5px;

  /* z-indexes */
  --z-mac-slider: 50;
  --z-privacy-popup: 60;
  --z-adv-player: 100;
  --z-dropdown: 110;

  --z-loader: 800;
  --z-mobile-filter: 900;

  --z-broadcast-banner-desktop: 900;

  --z-download-gc-overlay: 1200;
  --z-connection-status: 9800;
  --z-modal: 999999;
  --z-hint: 10001;
  --z-app-shell: 99999;
  --z-under-hint: calc(var(--z-hint) - 1);

  --z-web-player-beta: 999999999;

  /* others */
  --transition-time-fast: 0.1s;
  --transition-time-middle: 0.2s;
  --transition-time: 0.4s;
  --content-width: 980px;
  --scrollbar-width: 14px;

  /*border-radius*/
  --border-radius-default: 12px;
  --border-radius-modal: 14px;
  --border-radius-circle: 50%;
  --border-radius-xxs: 4px;
  --border-radius-xs: 5px;
  --border-radius-s: 8px;
  --border-radius-m: 24px;
  --border-radius-l: 36px;

  /*border-color*/
  --border-hover-color: #5f6065;

  /* border */
  --border-opacity-12: 1px solid var(--color-white-opacity-12);
  --border-opacity-24: 1px solid var(--color-white-opacity-24);
  --border-opacity-48: 1px solid var(--color-white-opacity-48);

  /*box-shadow*/
  --box-shadow-modal: 0 8px 24px var(--color-black-opacity-6),
    0 38px 76px var(--color-black-opacity-40);

  /* button type components min-heights */
  --button-min-height-s: 32px;
  --button-min-height-m: 44px;
  --button-min-height-l: 56px;

  /*positions*/
  --dropdown-left: -65px;

  /*widths*/
  --dropdown-content-width: 200px;

  /* button type components width */
  --button-min-width: 312px;
  --button-width: 312px;
  --button-max-width: 312px;

  --limit-max-width: 310px;
  --limit-min-width: 310px;

  @for $i from 0 through 100 {
    @if $i % 5 == 0 {
      $opacity: $i * 0.01;
      --color-black-opacity-#{$i}: rgba(0, 0, 0, #{$opacity});
      --color-white-opacity-#{$i}: rgba(255, 255, 255, #{$opacity});
    }
  }
}
