/* autoprefixer grid: autoplace */

@import 'assets/styles/variables';

* {
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
  min-height: 100%;
}

body {
  overflow-y: scroll;
  color: var(--color-text);
  font-family: 'VK Sans Display', system-ui, sans-serif;
  background-color: var(--color-dark-bg);
}

.no-scroll {
  overflow-y: hidden;
}

.wrapper {
  position: relative; /* нужен для позиционирования картинки бекграунда */
  box-sizing: border-box;
  height: 100%;
  min-height: 100%;
}

@media (max-width: 980px) and (orientation: portrait) {
  .wrapper {
    height: auto; /* для удаления лишних скроллов на мобилках и планшетах */
  }
}

.page {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.page-content {
  /* необходим для контекста всего приложения (ниже шапки), иначе, абсолютные элементы залезают на неё (крестик попапа) */
  position: relative;
  flex: 1;
  overflow: hidden;

  &.overflow-content {
    overflow: visible;
  }
}

/**
  router div wrappers style
 */
.RootRoutes,
.WebPlayerRoutes {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.WebPlayerRoutes {
  flex: 1;
  align-items: center;
  justify-content: center;
}

/* custom scrollbar START */

/* Кастомный скролл необходимо поддерживаться для ИЦ, иначе выглядит не консистентно
В браузерах вполне сойдет нативный */

/* https://dev.to/xtrp/how-to-create-a-beautiful-custom-scrollbar-for-your-site-in-plain-css-1mjg */

::-webkit-scrollbar {
  width: var(--scrollbar-width);
  background-color: #242427;
}

::-webkit-scrollbar-track {
  background-color: #242427;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(143, 143, 144, 0.5);
  background-clip: content-box;
  border: 3px solid transparent;
  border-radius: 10px;
}
/* custom scrollbar END */

/* global chunk error START */
.cg-update-error {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--color-text);
  font-size: 30px;
  background-color: var(--color-dark-bg);
}

.cg-update-error__button-wrap {
  margin-top: 20px;
}

.cg-update-error__button {
  padding: 7px 16px;
  color: var(--color-text);
  font-size: 17px;
  line-height: 32px;
  background-color: transparent;
  border: 1px solid var(--color-white-opacity-30);
  border-radius: 3px;
  cursor: pointer;
}

.cg-update-error__button:hover {
  color: #42407e;
  background-color: var(--color-text);
}

.cg-update-error__button:active {
  color: #42407e;
  background-color: #e1e1e1;
}

.wrapper.MiniApp {
  display: flex;
  flex-direction: column;
  height: auto;
  overflow-x: hidden;

  .BannerMarketing {
    margin-top: 0;
  }

  .RoutesWrapper {
    display: flex;
    flex: 100%;
    flex-direction: column;
  }
}
/* global chunk error END */

@font-face {
  font-family: 'VK Sans Display Expanded Medium';
  src: local('VK Sans Display Expanded Medium'),
    url('./fonts/VK_Sans_Display_Expanded_Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'VK Sans Display Expanded Bold';
  src: local('VK Sans Display Expanded Bold'),
  url('./fonts/VK_Sans_Display_Expanded_Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'VK Sans Display Expanded DemiBold';
  src: local('VK Sans Display Expanded DemiBold'),
  url('./fonts/VK_Sans_Display_Expanded_DemiBold.woff2') format('woff2');
}

@font-face {
  font-family: 'VK Sans Display';
  src: local('VK Sans Display'),
  url('fonts/VK_Sans_Display_Regular.ttf') format('ttf'),
  url('./fonts/VK_Sans_Display_Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'VK Sans Display Regular';
  src: local('VK Sans Display Regular'),
  url('fonts/VK_Sans_Display_Regular.ttf') format('ttf'),
  url('./fonts/VK_Sans_Display_Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'VK Sans Display Bold';
  src: local('VK Sans Display Bold'),
  url('fonts/VK_Sans_Display_Bold.ttf') format('ttf');
}
